import React, { useEffect } from "react";
import { navigate } from "hookrouter";
import SocialShare from "./generic/SocialShares";
// Custom Modules
import scrollHandler from "./generic/scrollHandler";


function IndexMain() {

      const shareURL = "https://www.backroom.co.uk";

      const scrollWelcome = scrollHandler(250);

      const scrollWelcome2 = scrollHandler(400);

      const scrollWelcome3 = scrollHandler(550);

      const navContact = () => {
            navigate("/contact");
        };

      
  
    return (
      <main>

            <section id="industries">
                  <div className="industries__container">
                        <h1 className="industries__heading-primary">Innovative Location Hire Equipment</h1>
                        <div className="industries__rows">
                              <div className="row margin-top-medium industries__1st-row">
                                    <div className="col-1-of-2">
                                          <img src="../images/The1975_1975TourManchester_0046_JH_1280.png" alt="The 1975 Tour Manchester" className="industries__image" />
                                          <h2 className="industries__heading">Concert Touring</h2>
                                    </div>
                                    <div className="col-1-of-2">
                                          <img src="../images/Film_1280.png" alt="The 1975 Tour Manchester" className="industries__image" />
                                          <h2 className="industries__heading">Film</h2>
                                    </div>
      
                              </div>

                              <div className="row industries__2nd-row">
                                    <div className="col-1-of-2">
                                          <img src="../images/Medical.png" alt="The 1975 Tour Manchester" className="industries__image" />
                                          <h2 className="industries__heading">Medical</h2>
                                    </div>
                                    
                                    <div className="col-1-of-2">
                                          <img src="../images/Research.png" alt="The 1975 Tour Manchester" className="industries__image" />
                                          <h2 className="industries__heading">Research</h2>
                                    </div>
      
                              </div>

                              <div className="industries__entertainment">
                                    <img src="../images/Entertainment.png" alt="The 1975 Tour Manchester" className="industries__image" />
                                    <h2 className="industries__heading">Entertainment</h2>
                              </div>
                        </div>
                  </div>

                  <div className="equipment__container">


                        <div className="equipment__textbox">
                              <h3 className="equipment__textbox-text">We have a wide range of equipment, whether your looking for transformers and power distribution or site lighting and logistical support, we are here to help.</h3>

                              <h3 className="equipment__textbox-text">Our experience working all over the world gives you confidence that we can deal with whatever is thrown at us. Whether it be local regulations, voltage and frequency variations or some other curve ball.</h3>
                              <h3 className="equipment__textbox-text">We have the skills to help your next production happen.</h3>
                        </div>
                        <img src="../images/Backroom_Distros_Transformers.png" alt="Power Distribution and Transformers" className="equipment__image" />

                        
                  </div>

                  <form onSubmit={navContact}>
                        <button className="button button__getintouch1" type ="submit">Get In Touch</button>
                  </form>

                  <div className="equipment__container-2">

                        <div className="row padding-top-small">
                              <div className="col-1-of-3">
                                    <img src="../images/equipment_pics/Air_Conditioning.png" alt="Air Conditioning" className="equipment__image-tile" />
                                    <h3 className="equipment__title">Air Conditioning</h3>
                              </div>

                              <div className="col-1-of-3">
                                    <img src="../images/equipment_pics/Heater.png" alt="Heater" className="equipment__image-tile" />
                                    <h3 className="equipment__title">Heating</h3>
                              </div>

                              <div className="col-1-of-3">
                                    <img src="../images/equipment_pics/Site_Light.png" alt="Site Light" className="equipment__image-tile" />
                                    <h3 className="equipment__title">Site Light</h3>
                              </div>
                        </div>

                        <div className="row">
                              <div className="col-1-of-3">
                                    <img src="../images/equipment_pics/Extreme_40_Series_Hex.png" alt="Extreme 40 Series Hex" className="equipment__image-tile" />
                                    <h3 className="equipment__title">Extreme 40 Series Hex</h3>
                              </div>

                              <div className="col-1-of-3">
                                    <img src="../images/equipment_pics/Gazebo.png" alt="Gazebo" className="equipment__image-tile" />
                                    <h3 className="equipment__title">Gazebo</h3>
                              </div>

                              <div className="col-1-of-3">
                                    <img src="../images/equipment_pics/Gazebo_Frame.png" alt="Gazebo Frame" className="equipment__image-tile" />
                                    {/* <h3 className="equipment__title"></h3> */}
                              </div>
                        </div>

                        <h3 className="equipment__title margin-top-neg-small">Other Equipment Available on Request</h3>

                  </div>

                  

                  <div id="film">
                        
                        <div className="film__container">
                              <h1 className="film__heading-primary">Films we have worked on</h1>
                              <img src="../images/Dumbo.png" alt="Dumbo" className="film__image film__image-portrait margin-top-small" />

                        </div>

                        <div className="film__container film__container-2">
                              <img src="../images/Game of Thrones_For The Throne.png" alt="Game of Thrones" className="film__image film__image-portrait" />

                        </div>

                        <div className="film__container film__container-3">
                              <img src="../images/The Greatest Showman.png" alt="The Greatest Showman" className="film__image film__image-portrait" />

                        </div>
                  </div>

                  <a href="https://www.facebook.com/powermeupnow">
                  <div id="facebook" className="facebook">

                        
                        <div className="facebook__container">
                              <div class="fb-page" data-href="https://www.facebook.com/powermeupnow" data-tabs="timeline" data-width="500" data-height="1000" data-small-header="false" 
                              data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                              <blockquote cite="https://www.facebook.com/powermeupnow" class="fb-xfbml-parse-ignore"></blockquote></div>
                        </div>
                        
                  </div>
                  </a>

                  


                  

            </section>


      

      </main>
    ); 
  };
  
  export default IndexMain;